<template>
    <div class="flex items-center">
        <div class="h-32 w-32 flex-none">
            <chart-pie :data="sectosChartData" :chartId="'chartId'">
                <div class=" flex flex-col justify-center items-center">
                    <span class="text-xs font-semibold text-white">{{ sectors.total.oportunity | numberFormat(0) }}</span>
                    <span class="text-font-gray text-xxs mt-2">{{ sectors.total.sales | reduceBigNumbers(2) }}€</span>
                </div>
            </chart-pie>
        </div>
        <div class="h-44 w-full overflow-auto no-scrollbar flex flex-col justify-between items-center py-2">
            <template v-for="(value, key, index) in sectors" v-if="key !== 'total'">
                <Sector :sector="value" :name="key" :color="catColors[index]" class="mt-2"/>
            </template>
        </div>
    </div>
</template>

<script>

    const Sector = () => import("@/components/Mobile/Pipeline/Sector");
    const ChartPie  = () => import('@/charts/ChartPie.vue');


    export default {
        components: {
            Sector, ChartPie
        },
        name: "PipelineByPercentageWithoutSlide",
        props: ['sectors'],
        computed: {
            sectosChartData() {
                let data = []
                let i = 0
                for (let key in this.sectors) {
                    if (key !== 'total') {
                        data.push({
                            name: key,
                            y: this.sectors[key].sales,
                            color: this.catColors[i]
                        })
                        i++
                    }
                }
                return data
            }
        }
    }
</script>